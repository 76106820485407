require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')
require('jquery')
require("@popperjs/core")

window.addEventListener('click', function () {
  if ($('.sidebar-header').hasClass('show')) {
    $('.p-1').trigger('click')
  }
})

document.addEventListener("DOMContentLoaded", function(event) {
  const toggle = document.getElementById('sidebar-toggle')
  const sidebar = document.getElementById('sidebar')

  console.log('loaded')

  toggle.addEventListener('click', () => {
    sidebar.classList.toggle('active')
  })
})

import "../stylesheets/application"
global.toastr = require("toastr")
